/* eslint-disable react/no-unescaped-entities */
import React, { useState }  from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import PhoneInput from 'react-phone-number-input';
import { useTheme } from '@mui/material/styles';
import ReCAPTCHA from 'react-google-recaptcha';
import { Box } from '@mui/material';
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { Divider } from '@mui/material';
import { navigate } from 'gatsby';

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please specify your first name'),
  lastName: yup
    .string()
    .trim()
    .min(1, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please specify your last name'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
    contactNumber: yup
    .string()
    .trim()
    .required('please enter phone number')
    .matches(
      /^(?:(?:\+|0{0,2})91(\s*|[ -])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/,
      'Please enter a valid phone number.'
    ),
  message: yup
    .string()
    .trim()
    .required('Please specify your message'),
});

const axios = require('axios').default;
let isDisabled = true;
const Hero = () => {
  
  const [captchaCode, setCaptchaCode] = useState('');
  const theme = useTheme();

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    query_type: 'contact',
    message: '',
  };

  const onSubmit = () => {
    axios
    .post('https://9j1kup2g1g.execute-api.ap-south-1.amazonaws.com/apiv1/sendemail', formik.values)
   
  

    return formik.values;
  };

  const Wredirect = () => {
    axios
    .post('https://9j1kup2g1g.execute-api.ap-south-1.amazonaws.com/apiv1/sendemail', formik.values)
   {
    globalThis.window.location = new URL(
      window.location.origin +
        `/thanks-for-submission`,
    ); 
    }
}

  const onChange = (valueCaptcha) => {
    setCaptchaCode(valueCaptcha.length);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  if (
    formik &&
    formik.values &&
    Object.keys(formik.errors).length === 0 &&
    captchaCode
  ) {
    isDisabled = false;
  }


  return (
    <Box maxWidth={600} margin={'0 auto'}>
      <Box marginBottom={4}>
        <Typography
          variant={'h4'}
          sx={{ fontWeight: 700 }}
          align={'center'}
          gutterBottom
        >
          Enquiry Us
        </Typography>
      </Box>
      <Box>
        
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="First name"
                variant="outlined"
                color="primary"
                size="medium"
                name="firstName"
                fullWidth
                defaultValue={formik.values.firstName}
                onChange={formik.handleChange('firstName')}
                error={
                  formik.touched.firstName || Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName || formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Last name"
                variant="outlined"
                color="primary"
                size="medium"
                name="lastName"
                fullWidth
                defaultValue={formik.values.lastName}
                onChange={formik.handleChange('lastName')}
                error={
                  formik.touched.lastName || Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName || formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Email"
                type="email"
                variant="outlined"
                color="primary"
                size="medium"
                name="email"
                fullWidth
                defaultValue={formik.values.email}
                onChange={formik.handleChange('email')}
                error={formik.touched.email || Boolean(formik.errors.email)}
                helperText={formik.touched.email || formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Phone number"
                type="contactNumber"
                variant="outlined"
                color="primary"
                size="medium"
                name="contactNumber"
                fullWidth
                defaultValue={formik.values.contactNumber}
                onChange={formik.handleChange('contactNumber')}
                error={formik.touched.contactNumber || Boolean(formik.errors.contactNumber)}
                helperText={formik.touched.contactNumber || formik.errors.contactNumber}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Ask Us Anything"
                multiline
                rows={6}
                variant="outlined"
                color="primary"
                size="medium"
                name="message"
                fullWidth
                defaultValue={formik.values.message}
                onChange={formik.handleChange('message')}
                error={formik.touched.message || Boolean(formik.errors.message)}
                helperText={formik.touched.message || formik.errors.message}
              />


            </Grid>
            <br />
            <br />
            <Grid item container justifyContent={'center'} xs={12} >
            <Box
            alignContent={'center'}>
               <ReCAPTCHA
                  sitekey="6LdnjA8iAAAAAL0iFw5FQzAk1rnONk2acKVt19a-
                  "
                  onChange={onChange}
                />
            </Box>
            </Grid>
            <br />
            <Grid item container justifyContent={'center'} xs={12} >
              <Button
                component={Button}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                height={54}
                marginTop={{ xs: 4, sm: 4 }}
                marginLeft={{ xs: 15, sm: 38 }}
                disabled={isDisabled}
                readOnly={isDisabled}
                onClick={onSubmit && Wredirect}
              >
                Submit
              </Button>
            </Grid>
            
            <Grid item container justifyContent={'center'} xs={12}>
              <Typography color="text.secondary">
              Please submit your Enquiry
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item container justifyContent={'center'} xs={12}>
              <Box>
                <Typography component="p" variant="body2" align="left">
                  By clicking on "submit" you agree to our{' '}
                  <Box
                    component="a"
                    href="/privacy-and-policy"
                    color={theme.palette.text.primary}
                    fontWeight={'700'}
                  >
                    Privacy Policy
                  </Box>
                  ,{' '}
                  <Box
                    component="a"
                    href="/terms-and-conditions"
                    color={theme.palette.text.primary}
                    fontWeight={'700'}
                  >
                    Terms & Conditions 
                  </Box>{' '}
                  and{' '}
                  <Box
                    component="a"
                    href="/cancellation-policy"
                    color={theme.palette.text.primary}
                    fontWeight={'700'}
                  >
                    Refund Policy
                  </Box>
                  .
                </Typography>
              </Box>
            </Grid>
           
          </Grid>
      </Box>
    </Box>
  );
};

export default Hero;